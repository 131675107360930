import React from "react";
import imgPlanFlash from '../images/dibus/plan_flashcoco.svg'
import imgPlanBatman from '../images/dibus/plan_batcoco.svg'
import imgPlanWonder from '../images/dibus/plan_wondercoco.svg'
import imgPlanSuper from '../images/dibus/plan_supercoco.svg'

const Planes = () => (
	<div className="row">
		<div className="col-sm-6 col-lg-3">
			<article className="plan plan-flashcoco">
				<h4 className="plan-title">Plan flashcoco</h4>

				<img width="65" className="plan-img" src={imgPlanFlash} title="Plan flashcoco" alt="flashcoco" />

				<p className="plan-price">Desde <span className="plan-amount">1.000€</span></p>
				<ul className="plan-features">
					<li>Landing page (máximo 6 secciones)</li>
					<li>Diseño y desarrollo</li>
					<li>Integración con redes sociales</li>
					<li>Email & teléfono</li>
				</ul>
			</article>
		</div>
		<div className="col-sm-6 col-lg-3">
			<article className="plan plan-batcoco">
				<h4 className="plan-title">Plan batcoco</h4>

				<img width="65" className="plan-img" src={imgPlanBatman} title="Plan batcoco" alt="batcoco" />

				<p className="plan-price">Desde <span className="plan-amount">2.500€</span></p>
				<ul className="plan-features">
					<li>5 páginas de contenido</li>
					<li>Ayuda con redacción de contenidos</li>
					<li>Hasta 10 imágenes stock</li>
					<li>Gestión de contenidos</li>
					<li>Formación</li>
				</ul>
			</article>
		</div>
		<div className="col-sm-6 col-lg-3">
			<article className="plan plan-wondercoco">
				<h4 className="plan-title">Plan wondercoco</h4>

				<img width="65" className="plan-img" src={imgPlanWonder} title="Plan wondercoco" alt="wondercoco" />

				<p className="plan-price">Desde <span className="plan-amount">4.900€</span></p>
				<ul className="plan-features">
					<li>8 páginas de contenido</li>
					<li>Redacción de contenidos</li>
					<li>Hasta 20 imágenes stock</li>
					<li>Gestión de contenidos</li>
					<li>Adaptación multi-idioma</li>
					<li>Formación</li>
				</ul>
			</article>

		</div>
		<div className="col-sm-6 col-lg-3">
			<article className="plan plan-supercoco">
				<h4 className="plan-title">Plan supercoco</h4>

				<img width="65" className="plan-img" src={imgPlanSuper} title="Plan supercoco" alt="supercoco" />

				<p className="plan-price">A consultar</p>
				<ul className="plan-features">
					<li>Aplicaciones web</li>
					<li>Aplicaciones móviles</li>
					<li>WordPress a medida</li>
					<li>Tiendas online</li>
					<li>Lo que se te ocurra, te lo desarrollamos a medida</li>
				</ul>
			</article>
		</div>									
	</div>
)

export default Planes